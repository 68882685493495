// Auth
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_AUTH_2FA = "SET_AUTH_2FA"
export const GET_PROFILE = "GET_PROFILE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const PROFILE_LOADING = "PROFILE_LOADING";
export const PROFILE_NOT_FOUND = "PROFILE_NOT_FOUND";
export const CLEAR_CURRENT_PROFILE = "CLEAR_CURRENT_PROFILE";

// Application
export const FETCH_APPLICATIONS = "FETCH_APPLICATIONS";
export const FILTER_APPLICATIONS = "FILTER_APPLICATIONS";
export const SORT_APPLICATIONS = "SORT_APPLICATIONS";
export const PUT_NOTE_APPLICATION = "PUT_NOTE_APPLICATION";
export const PUT_WORK_STATUS = "PUT_WORK_STATUS";
export const DELETE_APPLICATION = "DELETE_APPLICATION";
export const SEND_FOLLOWUP_EMAIL = 'SEND_FOLLOWUP_EMAIL';
export const FETCH_WEBSITES = 'FETCH_WEBSITES';

// Blaclist 
export const ADD_BLACKLIST_PHONE = 'ADD_BLACKLIST_PHONE';
export const REMOVE_BLACKLIST_PHONE = 'REMOVE_BLACKLIST_PHONE';
export const BLACKLIST_PHONES = 'BLACKLIST_PHONES';


// Loader
export const LOADER_START = "LOADING_SPINNER_START";
export const LOADER_COMPLETE = "LOADING_SPINNER_COMPLETE";
export const LOADER_ERROR = "LOADING_SPINNER_ERROR";
export const FORM_LOADING_START = "FORM_LOADING_START";
export const FORM_LOADING_FINISH = "FORM_LOADING_FINISH";

// Modal
export const MODAL_SHOW = "Modal/SHOW";
export const MODAL_HIDE = "Modal/HIDE";
export const MODAL_BUTTON_LOADING_START = "Modal/BUTTON_LOADING";
export const MODAL_BUTTON_LOADING_END = "Modal/BUTTON_FINISH";

// Users
export const UPDATE_USER = "UPDATE_USER";
export const COMPLETE_UPDATE_USER = "COMPLETE_UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const COMPLETE_DELETE_USER = "COMPLETE_DELETE_USER";

// Exceptions
export const GET_ERRORS = "GET_ERRORS";
