import React, { Component,useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  createNotification,
  pageTitle,
  isStaff,
  currency
} from "../../utils/helpers";
import DocumentException from "../common/DocumentException";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { isEmpty, has } from "lodash";
import AppTable from "./AppTable"
import { throws } from "assert";
import clsx from "clsx"
import { useMediaQuery } from "react-responsive"
import classNames from "classnames";

const MySwal = withReactContent(Swal);

const Dashboard = (props) => {
  const [states, setStates] = useState({
    sn: "",
    loading: false,
    qrcodeShow: false,
    result: null,
    agree: false,
    confirmApprove: false
  })

  function onApprove(e) {
    if (states.agree === false) {
      MySwal.fire({
        type: "warning",
        title: "Please tick and read agreements"
      });
      return;
    }
    MySwal.fire({
      title: "Are you sure want to approve this application?",
      text: "This button will change status to approved",
      showCancelButton: true
    }).then(prompt => {
      if (has(prompt, 'value')) {
        MySwal.fire({
          title: <p>Loading...</p>,
          onOpen: () => {
            MySwal.showLoading();
            props
              .approveApp(this.state.sn)
              .then(res => {
                MySwal.fire({
                  type: "success",
                  title: "Great!",
                  text: "Application has been approved",
                  timer: 2000
                });
                makeDefaultState();
              })
              .catch(err => {

                MySwal.fire({
                  type: "error",
                  title: "Error Exception!",
                  text: has(err, "data")
                    ? err.data.messages
                    : "Something went wrong"
                });
                makeDefaultState();
              });
          }
        });
      }
    });
  }

  function makeDefaultState() {
    setStates({
      ...states,
      loading: false,
      result: false,
      qrcodeShow: false,
      agree: false,
      sn: "",
      confirmApprove: false
    });
  }

  function findApplication() {
    setStates({
      ...states,
      loading: true,
      result: false
    });
    props.findApp(this.state.sn).then(res => {
      setStates({
        ...states,
        loading: false,
        result: true,
        qrcodeShow: false
      });
    });
  }

  const { user } = props.auth.user
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })

  const isPhone = useMediaQuery({maxDeviceWidth:414,orientation:"portrait"})

  return (
    <DocumentException
      title={pageTitle("Dashboard")}
      className="dashboardPage"
      history={props.history}
      url={props.location.pathname}
    >
      <div className="container">
        <div className="row justify-content-md-center mt-3">
          <div className="col-sm-12 col-lg-12 mb-3">
            <div className="card mb-3">
              <div className="card-header text-center">
                <h5>Hi, {user.name}</h5>
              </div>

              <div className={classNames("card-body")}>
                  <React.Fragment>
                    <div className="row">
                      <div className="col-12" style={{ minHeight: 200 }}>
                        {user && <AppTable history={props.history} user={user} />}
                      </div>
                    </div>
                  </React.Fragment>
                </div>

            </div>
          </div>
        </div>
      </div>
    </DocumentException>
  );
}

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStatetoProps = state => ({
  auth: state.auth,
  user: state.auth.user.user,
});

export default connect(
  mapStatetoProps,
  {}
)(Dashboard);
